
































































import { defineComponent, computed, ref } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { Card, ConfirmModal, SvgImage, Pagination } from '@/app/components';
import { NotificationOverview } from '@/modules/notification/components';
import store from '@/app/store';
import { useNotifications } from '@/modules/notification/composable/notifications';
import { NotificationAPI } from '../api';

export default defineComponent({
    name: 'Notifications',
    components: { Card, ConfirmModal, NotificationOverview, SvgImage, Pagination },
    setup(props, { root }) {
        const { exec } = useAxios();

        const currentPage = ref<number>(1);
        const pageSize = 10;
        const notificationsElement = ref<HTMLElement>();
        const { markAllAsSeen, showMarkAllAsSeenModal } = useNotifications(root);

        const notifications = computed(() => {
            return store.state.notificationEngine.notifications;
        });

        const visibleNotifications = computed(() => {
            return notifications.value.slice(
                (currentPage.value - 1) * pageSize,
                (currentPage.value - 1) * pageSize + pageSize,
            );
        });

        const destroy = async (id: number) => {
            await exec(NotificationAPI.delete(id))
                .then(() => {
                    store.commit.notificationEngine.DELETE_NOTIFICATION(id);
                    (root as any).$toastr.removeByType('success');
                    (root as any).$toastr.s('Notification deleted successfully', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Deleting Notification failed', 'Error');
                });
        };

        const enableMarkAllAsSeen = computed(() => {
            return !!(notifications.value.length && notifications.value.filter((n) => !n.seenAt).length);
        });

        const markAsSeen = async (id: number) => {
            await store.dispatch.notificationEngine.changeSeenAt(id);
        };

        const scrollToTop = () => {
            if (notificationsElement.value) {
                notificationsElement.value.scrollIntoView({ behavior: 'smooth' });
            }
        };

        return {
            destroy,
            notifications,
            pageSize,
            enableMarkAllAsSeen,
            markAsSeen,
            markAllAsSeen,
            showMarkAllAsSeenModal,
            visibleNotifications,
            currentPage,
            scrollToTop,
            notificationsElement,
        };
    },
});
