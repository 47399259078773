








































































import { computed, defineComponent, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import { TimeAgo } from 'vue2-timeago';
import { ErrorCodes } from '@/app/constants/error-codes';
import { notificationDescription, notificationTitle } from '../config/messages.templates';
import {
    NotificationDataCheckinJobIcon,
    NotificationWorkflowIcon,
    NotificationSuggestionIcon,
    NotificationDigestIcon,
} from './icons';

export default defineComponent({
    name: 'NotificationOverview',
    components: { TimeAgo },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const isHovered = ref(false);

        const markAsSeen = () => {
            emit('markAsSeen');
        };

        const deleteNotification = () => {
            emit('deleteNotification');
        };

        const createRedirectionConfiguration = computed(() => {
            let payload = null;

            switch (props.notification.payload.referenceType) {
                case 'DataCheckinJob':
                    payload = {
                        name: 'data-checkin-jobs',
                        params: {
                            id: parseInt(props.notification.payload.referenceId, 10),
                            status: props.notification.eventType,
                            type: props.notification.payload.referenceType,
                        },
                    };
                    break;
                case 'Workflow':
                    payload = {
                        name: 'history',
                        params: {
                            id: props.notification.payload.executionId,
                            workflowId: props.notification.payload.referenceId,
                            status: props.notification.eventType,
                            type: props.notification.payload.referenceType,
                        },
                    };
                    break;
                case 'ModelSuggestion':
                    if (props.notification.eventType == 'concept.created') {
                        payload = {
                            name: 'model-manager:edit',
                            params: {
                                id: props.notification.payload.referenceId,
                                suggestionId: props.notification.payload.suggestionId,
                                parentConceptName: props.notification.payload.parentConceptName,
                            },
                        };
                    } else {
                        payload = {};
                    }
                    break;
                case 'daily_digest':
                case 'weekly_digest':
                    payload = {
                        name: 'notifications-digest',
                        params: {
                            notificationGroups: props.notification.payload,
                            createdAt: props.notification.createdAt,
                            digestType: props.notification.eventType,
                        },
                    };
                    break;
                default:
                //
            }

            return payload;
        });

        const notificationType = (type: string) => {
            switch (type) {
                case 'DataCheckinJob':
                    return NotificationDataCheckinJobIcon;
                case 'Workflow':
                    return NotificationWorkflowIcon;
                case 'ModelSuggestion':
                    return NotificationSuggestionIcon;
                case 'daily_digest':
                case 'weekly_digest':
                    return NotificationDigestIcon;
                default:
                    return null;
            }
        };

        const description = computed(() => {
            if (props.notification.payload.errorCode) {
                return (
                    ErrorCodes[props.notification.payload.errorCode] ||
                    `Unknown Error Code: ${props.notification.payload.errorCode}`
                );
            }
            return notificationDescription(props.notification);
        });

        const title = computed(() => {
            return notificationTitle(props.notification);
        });

        return {
            dayjs,
            isHovered,
            notificationDescription,
            ErrorCodes,
            createRedirectionConfiguration,
            notificationType,
            description,
            title,
            markAsSeen,
            deleteNotification,
        };
    },
});
