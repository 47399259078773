












































import { defineComponent, computed, ref } from '@vue/composition-api';
import TailwindAccodion from '@/app/components/tailwind-accordion/TailwindAccordion.vue';
import TailwindAccodionCard from '@/app/components/tailwind-accordion/TailwindAccordionCard.vue';
import store from '@/app/store';
import ModelSuggestionDigest from '../components/eventDigests/ModelSuggestionDigest.vue';
import { NotificationSuggestionIcon, NotificationWorkflowIcon } from '../components/icons';
import NotificationDataCheckinJobIcon from '../components/icons/NotificationDataCheckinJobIcon.vue';
import DataCheckinJobDigest from '../components/eventDigests/DataCheckinJobDigest.vue';
import WorkflowDigest from '../components/eventDigests/WorkflowDigest.vue';
import * as R from 'ramda';

export default defineComponent({
    name: 'NotificationsDigest',
    components: { TailwindAccodion, TailwindAccodionCard },
    props: {
        notificationGroups: {
            type: Object,
            required: true,
        },
        createdAt: {
            type: String,
            required: true,
        },
        digestType: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const currentPage = ref<number>(1);
        const pageSize = 10;

        const notifications = computed(() => {
            return store.state.notificationEngine.notifications;
        });

        const visibleNotifications = computed(() => {
            return notifications.value.slice(
                (currentPage.value - 1) * pageSize,
                (currentPage.value - 1) * pageSize + pageSize,
            );
        });

        const components = {
            ModelSuggestion: {
                header: 'Quick Data Model(s) Update',
                component: ModelSuggestionDigest,
                icon: NotificationSuggestionIcon,
            },
            DataCheckinJob: {
                header:
                    'Quick Data Collection Task(s) Update: <span class="text-neutral-600">Review successful and failed executions</span>',
                component: DataCheckinJobDigest,
                icon: NotificationDataCheckinJobIcon,
            },
            Workflow: {
                header:
                    'Quick Workflow(s) Update: <span class="text-neutral-600">Review successful and failed executions</span>',
                component: WorkflowDigest,
                icon: NotificationWorkflowIcon,
            },
        };

        const date = {
            startDate: () => {
                const endDate = new Date(props.createdAt);
                const startDate = new Date(props.createdAt);
                const ndays = props.digestType === 'daily_digest' ? 1 : 7;
                startDate.setTime(endDate.getTime() - ndays * 24 * 60 * 60 * 1000);
                return startDate.toLocaleString();
            },
            endDate: new Date(props.createdAt).toLocaleString(),
        };

        const filterNotificationGroups = () => {
            const filterNot = Object.keys(props.notificationGroups)
                .filter((key) => key !== 'baseUrl' && key != 'referenceId' && key != 'referenceType')
                .reduce((obj, key) => {
                    return R.assoc(key, props.notificationGroups[key], obj);
                }, {});
            return filterNot;
        };

        return {
            notifications,
            pageSize,
            visibleNotifications,
            currentPage,
            TailwindAccodion,
            TailwindAccodionCard,
            components,
            date,
            filterNotificationGroups,
        };
    },
});
